import { extendTheme } from '@chakra-ui/react'

const config = {
  initialColorMode: `light`,
  useSystemColorMode: true,
}

export const theme = extendTheme({
  config,
  styles: {
    global: {
      html: {
        fontFamily: `'Open Sans', system-ui, sans-serif`,
      },
    },
  },
  fonts: {
    heading: `'Open Sans', system-ui, sans-serif`,
    body: `'Open Sans', system-ui, sans-serif`,
    mono: `monospace`,
  },
  colors: {
    primary: {
      main: `#d0ccd0`,
      50: `#f6f1f6`,
      100: `#dad7da`,
      200: `#c0bec0`,
      300: `#a7a3a7`,
      400: `#8e898e`,
      500: `#746f74`,
      600: `#5b575b`,
      700: `#413e41`,
      800: `#282528`,
      900: `#100a10`,
    },
    secondary: {
      main: `#254156`,
      50: `#e5f4fe`,
      100: `#c8daea`,
      200: `#a7c2d7`,
      300: `#85a9c6`,
      400: `#6491b5`,
      500: `#4c779c`,
      600: `#3a5d7a`,
      700: `#284258`,
      800: `#152837`,
      900: `#010e18`,
    },
    accent: {
      main: `#b75328`,
      50: `#ffece1`,
      100: `#f6cebc`,
      200: `#eaae94`,
      300: `#e08f6c`,
      400: `#d66f43`,
      500: `#bc5529`,
      600: `#93421f`,
      700: `#6a2e15`,
      800: `#421a0a`,
      900: `#1d0500`,
    },
  },
})

export default theme
