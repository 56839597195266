exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-amenities-index-tsx": () => import("./../../../src/pages/amenities/index.tsx" /* webpackChunkName: "component---src-pages-amenities-index-tsx" */),
  "component---src-pages-amenities-wp-amenity-slug-tsx": () => import("./../../../src/pages/amenities/{WpAmenity.slug}.tsx" /* webpackChunkName: "component---src-pages-amenities-wp-amenity-slug-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-articles-wp-post-slug-tsx": () => import("./../../../src/pages/articles/{WpPost.slug}.tsx" /* webpackChunkName: "component---src-pages-articles-wp-post-slug-tsx" */),
  "component---src-pages-make-reservation-tsx": () => import("./../../../src/pages/make-reservation.tsx" /* webpackChunkName: "component---src-pages-make-reservation-tsx" */),
  "component---src-templates-blank-tsx": () => import("./../../../src/templates/blank.tsx" /* webpackChunkName: "component---src-templates-blank-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-photo-gallery-tsx": () => import("./../../../src/templates/photo-gallery.tsx" /* webpackChunkName: "component---src-templates-photo-gallery-tsx" */)
}

